<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <div class="mt-1 mb-3">
          <b-row>
            <b-col
              cols="12"
            >
              <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50 text-primary"
                  size="20"
                />
                <b-input-group class="input-group-merge shadow-none">
                  <b-form-input
                    placeholder="Rechercher un produit..."
                    style="height: auto;"
                    class="border-0 shadow-none"
                    @input="filter"
                  />
                </b-input-group>
              </div>
            </b-col>
            <b-col
              cols="12"
            >
              <a href="/solution-create">
                <b-button
                  variant="primary"
                  class="btn-cart mt-1"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Créer une solution</span>
                </b-button>
              </a>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-card title="Vous pouvez modifier les solutions directement ici.">
      <b-row class="match-height">
        <b-col
          v-for="(solution, keyP) in solutions"
          :key="keyP"
          md="3"
          lg="3"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div
              v-if="solution.images && solution.images.length"
              class="item-img text-center"
            >
              <!-- <b-img
                :alt="`${solution.title}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="solution.images[0].src"
              /> -->
            </div>

            <!-- solution Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div>
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ solution.type }}
                  </h5>
                  <h6 class="item-price text-primary text-lighten-2">
                    {{ solution.subYype }}
                  </h6>
                </div>
              </div>
              <p class="item-name">
                {{ solution.title }}
              </p>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Solution Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="openModal(solution)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier la solution</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  // BImg,
  BButton,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // BImg,
    BButton,
    BCardBody,
    BFormInput,
    BInputGroup,
  },
  data() {
    return {
      baseSolutions: [],
      solutions: [],
      product: {},
      showModal: false,
      options: { mode: 'code' },
      categoryInformations: [],
    }
  },
  async mounted() {
    try {
      const { solutions } = await this.$http.get('/admin/solution')
      this.solutions = solutions
      this.baseSolutions = solutions
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    filter(e) {
      this.filterQuery = e
      if (!e || e === '') {
        this.solutions = this.baseSolutions
      } else {
        const value = e
        this.solutions = this.baseSolutions.filter(base => base.title.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>
